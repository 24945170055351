exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-opt-build-repo-blog-how-to-buy-a-domain-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/blog/how-to-buy-a-domain/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-opt-build-repo-blog-how-to-buy-a-domain-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-opt-build-repo-blog-what-is-cms-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/blog/what-is-cms/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-opt-build-repo-blog-what-is-cms-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-opt-build-repo-blog-what-is-ui-ux-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/blog/what-is-ui-ux/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-opt-build-repo-blog-what-is-ui-ux-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-opt-build-repo-blog-why-you-need-gatsby-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/blog/why-you-need-gatsby/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-opt-build-repo-blog-why-you-need-gatsby-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-opt-build-repo-blog-why-you-need-react-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/blog/why-you-need-react/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-opt-build-repo-blog-why-you-need-react-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-opt-build-repo-blog-why-you-need-to-start-switching-from-css-to-scss-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/blog/why-you-need-to-start-switching-from-css-to-scss/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-opt-build-repo-blog-why-you-need-to-start-switching-from-css-to-scss-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-opt-build-repo-blog-why-you-need-vue-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/blog/why-you-need-vue/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-opt-build-repo-blog-why-you-need-vue-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-opt-build-repo-blog-why-you-should-learn-javascript-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/blog/why-you-should-learn-javascript/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-opt-build-repo-blog-why-you-should-learn-javascript-index-mdx" */),
  "component---src-pages-boring-index-jsx": () => import("./../../../src/pages/boring/index.jsx" /* webpackChunkName: "component---src-pages-boring-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-playground-background-generator-index-jsx": () => import("./../../../src/pages/playground/background-generator/index.jsx" /* webpackChunkName: "component---src-pages-playground-background-generator-index-jsx" */),
  "component---src-pages-playground-blackboard-index-jsx": () => import("./../../../src/pages/playground/blackboard/index.jsx" /* webpackChunkName: "component---src-pages-playground-blackboard-index-jsx" */),
  "component---src-pages-playground-color-palette-index-jsx": () => import("./../../../src/pages/playground/color-palette/index.jsx" /* webpackChunkName: "component---src-pages-playground-color-palette-index-jsx" */),
  "component---src-pages-playground-index-jsx": () => import("./../../../src/pages/playground/index.jsx" /* webpackChunkName: "component---src-pages-playground-index-jsx" */),
  "component---src-pages-playground-piano-index-jsx": () => import("./../../../src/pages/playground/piano/index.jsx" /* webpackChunkName: "component---src-pages-playground-piano-index-jsx" */),
  "component---src-pages-projects-ab-inwestor-index-jsx": () => import("./../../../src/pages/projects/ab-inwestor/index.jsx" /* webpackChunkName: "component---src-pages-projects-ab-inwestor-index-jsx" */),
  "component---src-pages-projects-achilles-index-jsx": () => import("./../../../src/pages/projects/achilles/index.jsx" /* webpackChunkName: "component---src-pages-projects-achilles-index-jsx" */),
  "component---src-pages-projects-advocate-index-jsx": () => import("./../../../src/pages/projects/advocate/index.jsx" /* webpackChunkName: "component---src-pages-projects-advocate-index-jsx" */),
  "component---src-pages-projects-baltic-silver-index-jsx": () => import("./../../../src/pages/projects/baltic-silver/index.jsx" /* webpackChunkName: "component---src-pages-projects-baltic-silver-index-jsx" */),
  "component---src-pages-projects-cheap-sites-index-jsx": () => import("./../../../src/pages/projects/cheap-sites/index.jsx" /* webpackChunkName: "component---src-pages-projects-cheap-sites-index-jsx" */),
  "component---src-pages-projects-condensa-pump-index-jsx": () => import("./../../../src/pages/projects/condensa-pump/index.jsx" /* webpackChunkName: "component---src-pages-projects-condensa-pump-index-jsx" */),
  "component---src-pages-projects-condensa-rental-index-jsx": () => import("./../../../src/pages/projects/condensa-rental/index.jsx" /* webpackChunkName: "component---src-pages-projects-condensa-rental-index-jsx" */),
  "component---src-pages-projects-index-jsx": () => import("./../../../src/pages/projects/index.jsx" /* webpackChunkName: "component---src-pages-projects-index-jsx" */),
  "component---src-pages-projects-investprotect-index-jsx": () => import("./../../../src/pages/projects/investprotect/index.jsx" /* webpackChunkName: "component---src-pages-projects-investprotect-index-jsx" */),
  "component---src-pages-projects-moje-miasto-index-jsx": () => import("./../../../src/pages/projects/moje-miasto/index.jsx" /* webpackChunkName: "component---src-pages-projects-moje-miasto-index-jsx" */),
  "component---src-pages-projects-pizzeria-luna-index-jsx": () => import("./../../../src/pages/projects/pizzeria-luna/index.jsx" /* webpackChunkName: "component---src-pages-projects-pizzeria-luna-index-jsx" */),
  "component---src-pages-projects-semup-index-jsx": () => import("./../../../src/pages/projects/semup/index.jsx" /* webpackChunkName: "component---src-pages-projects-semup-index-jsx" */),
  "component---src-pages-projects-upadlosc-prawnik-index-jsx": () => import("./../../../src/pages/projects/upadlosc-prawnik/index.jsx" /* webpackChunkName: "component---src-pages-projects-upadlosc-prawnik-index-jsx" */),
  "component---src-pages-projects-wojciech-koszczynski-index-jsx": () => import("./../../../src/pages/projects/wojciech-koszczynski/index.jsx" /* webpackChunkName: "component---src-pages-projects-wojciech-koszczynski-index-jsx" */),
  "component---src-pages-projects-zielone-drzwi-index-jsx": () => import("./../../../src/pages/projects/zielone-drzwi/index.jsx" /* webpackChunkName: "component---src-pages-projects-zielone-drzwi-index-jsx" */)
}

