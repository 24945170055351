module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bartlomiej Nowak website","short_name":"BN website","description":"Bartlomiej Nowak website","lang":"en","display":"standalone","icon":"src/images/icon.png","start_url":"/","background_color":"#336999","theme_color":"#4287f5","localize":[{"start_url":"/en/","lang":"en","name":"Bartlomiej Nowak website","short_name":"BN website","description":"Bartlomiej Nowak website"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c4c70c653d6517adc2456ecd74171a4a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/","/blog/*","/about"]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
